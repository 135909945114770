import { graphql } from 'gatsby';
import * as React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import _ from 'lodash';
import LazyHero from 'react-lazy-hero';

import Footer from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import PostCard from '../components/PostCard';
import Wrapper from '../components/Wrapper';
import IndexLayout from '../layouts';
import config from '../website-config';
import siteConfig from '../utils/siteConfig'
import { Provider, Heading, Subhead , Pre, Flex, Box, Card, Image, Text } from 'rebass'
import {
  Hero, CallToAction, ScrollDownIndicator, Section, SignUp, Feature, Checklist
} from 'react-landing-page'
import HubspotForm from 'react-hubspot-form'

import {
  inner,
  outer,
  PostFeed,
  PostFeedRaise,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
} from '../styles/shared';
import { PageContext } from '../templates/post';

const HomePosts = css`
  @media (min-width: 795px) {
    .post-card:nth-of-type(6n + 1):not(.no-image) {
      flex: 1 1 100%;
      flex-direction: row;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      border-radius: 5px 0 0 5px;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content {
      flex: 0 1 357px;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) h2 {
      font-size: 2.6rem;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) p {
      font-size: 1.8rem;
      line-height: 1.55em;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content-link {
      padding: 30px 40px 0;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-meta {
      padding: 0 40px 30px;
    }
  }
`;

export interface IndexProps {
  data: {
    logo: {
      childImageSharp: {
        fixed: any;
      };
    };
    header: {
      childImageSharp: {
        fluid: any;
      };
    };
    allGhostPost: {
      edges: {
        node: PageContext;
      }[];
    };
  };
}

const IndexPage: React.FunctionComponent<IndexProps> = props => {
  // console.log(props)
  const HeroDiv = styled.div`
    & ::before {
      background-image: url(${props.data.header.childImageSharp.fluid.src});
      background-size: cover;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      // opacity: 0.4;
    }
    & ::after {
      background-color: #000;
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.5;
    }
  `;

  const width = props.data.header.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0];
  const height = String(Number(width) / props.data.header.childImageSharp.fluid.aspectRatio);
  return (
    <IndexLayout css={HomePosts}>
      <Helmet>
        <html lang={config.lang} />
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.title} />
        <meta property="og:description" content={config.description} />
        <meta property="og:url" content={config.siteUrl} />
        <meta
          property="og:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fluid.src}`}
        />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={config.title} />
        <meta name="twitter:description" content={config.description} />
        <meta name="twitter:url" content={config.siteUrl} />
        <meta
          name="twitter:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fluid.src}`}
        />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        <meta property="og:image:width" content={width} />
        <meta property="og:image:height" content={height} />
      </Helmet>
      <Wrapper>

      <HeroDiv
        css={[outer, SiteHeader]}
      >
        <div css={inner}>
          <SiteHeaderContent>
            <SiteTitle>
              {props.data.logo ? (
                <img
                  style={{ maxHeight: '45px' }}
                  src={props.data.logo.childImageSharp.fixed.src}
                  alt={config.title}
                />
              ) : (
                config.title
              )}
            </SiteTitle>
            <SiteDescription>{config.description}</SiteDescription>
            <SiteDescription>为您的校友会找一个家</SiteDescription>
            <SiteDescription>为您的活动找一个家</SiteDescription>
          </SiteHeaderContent>
        </div>
      </HeroDiv>
      <SiteNav isHome />
      <Provider>


<Section mt={[10,-30,-70,-100]} p={0}>

<Heading>为什么使用校聚网?</Heading>
{/* <Subhead>maybe this will help</Subhead> */}
<Flex>

<Checklist 
  children={[
    '校友会及校友会活动推广平台',
    '校友和校二代风采推广平台',
    '自助式专业文章、新闻发布平台',
    '自助式网站建立编辑系统',
    '专业设计的网站模板',
    '会用编辑器就会使用的建站系统',
    '安全高效的网站系统',
    '能够从中国大陆访问',
  ]}
  checkmark='❤️'
  mx={3}
/>
<Checklist 
  children={[
    '像美篇一样简单易用',
    '集中管理发布内容',
    '建立您的独立品牌形象',
    '支持自己的顶级域名',
    '可使用校聚网二级域名',
    '可申请设置数字安全证书',
    '所有功能对校友会免费开放',
    '促进中加校友会交流',
  ]}
  checkmark='❤️'
  mx={3}
/>
</Flex>
{/* <Flex>
<Box width={256}>
  <Card
    p={1}
    borderRadius={2}
    boxShadow='0 0 16px rgba(0, 0, 0, .25)'>
    <Image src={props.data.header.childImageSharp.fluid.src} />
    <Box px={2}>
      <Heading as='h3'>
        Card
      </Heading>
      <Text fontSize={0}>
        Small meta text
      </Text>
    </Box>
  </Card>
</Box>
<Box width={256}>
  <Card
    p={1}
    borderRadius={2}
    boxShadow='0 0 16px rgba(0, 0, 0, .25)'>
    <Image src={props.data.header.childImageSharp.fluid.src} />
    <Box px={2}>
      <Heading as='h3'>
        Card
      </Heading>
      <Text fontSize={0}>
        Small meta text
      </Text>
    </Box>
  </Card>
</Box>
</Flex> */}
<Heading mt={0} mb={[-40, -60, -80, -100]} fontSize={[ 3, 4 ]} id="contact">
  欢迎注册开始您的校聚之路
</Heading>
<Section>
  <HubspotForm
    portalId='5718469'
    formId='a75dd046-ba71-4ebc-86a6-e09843d9fbbb'
    onSubmit={() => console.log('Submit!')}
    onReady={(form) => console.log('Form ready!')}
    loading={<div>Loading...</div>}
    mt={0}
  />
</Section>
<Heading mb={30} mt={-60}>
  最新发布文章和新闻
</Heading>
</Section>
        </Provider>

        <main id="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <div css={[PostFeed, PostFeedRaise]}>
              {props.data.allGhostPost.edges.map(post => {
                // console.log(`---test-each post`)
                // console.log(post)
                let postAuthors = _.map(post.node.authors, 'slug')
                // console.log(`---postAuthors`)
                // console.log(postAuthors)
                // Find the association for this post
                let tempAssociation = _.find(siteConfig.associations, function (o) {
                    // console.log(`---association to be checked `, o)
                    return (_.some(o.authors, function (tempAuthor) {
                      if (_.indexOf(postAuthors, tempAuthor) >= 0) {
                        // console.log(`---matched`)
                        return true
                      } else {
                          return false
                      }}))
                    }) 
                //should just skip if no association found for that post authors, 
                //need to have the siteconfig file to include all association/event organization names
                //otherwise there will be error to generate the index page
                if (tempAssociation) {
                    post.node.associationName = tempAssociation.name
                    post.node.url = tempAssociation.url

                    // console.log(`---the tempAssociation is `, tempAssociation)
                    // This part here defines, that our posts will use
                    // a `/:slug/` permalink.
                    post.node.url = `${tempAssociation.url}/${post.node.slug}/`
                    
                    return (
                        <PostCard key={post.node.slug} post={post.node} />
                    );
                }
              })}
            </div>
          </div>
        </main>
        
        {props.children}

        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "img/xiaoju-icon-y.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    header: file(relativePath: { eq: "img/xiaoju-landing1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allGhostPost(sort: {order: DESC, fields: published_at}, filter: {tags: {elemMatch: {name: {in: ["event","people","about"]}}}}) {
          edges {
                  node {
                      slug
                      feature_image
                      localImage {
                        childImageSharp {
                          fluid(maxWidth: 3720) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      title
                      excerpt
                      tags {
                          name
                          id
                      }
                      authors {
                          id
                          slug
                          name
                          profile_image
                      }
                  }
          }
    }, 
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { draft: { ne: true } } },
      limit: 1000,
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            date
            tags
            draft
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fixed(quality: 90) {
                      src
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;
